import {openGoodsModal, submitCopyToAll} from "@/events/common";

export default {
  methods:{
    copy(field, value, action, index = null, filter = {}){
      openGoodsModal.trigger({field, value, action, index, filter})
    },
    copyToAll(field, value){
      submitCopyToAll.trigger({field, value})
    }
  }
}